import Navbar from "../components/Navbar";

export default function AlgoSort() {
  return (
    <>
      <Navbar />
      <div id="algo-sort-page">

      </div>
    </>
  );
}